<template>
    <router-view />
</template>

<script>
import { provide,onMounted,onUnmounted, reactive } from 'vue'
import * as echarts from 'echarts'
import axios from "axios";
export default {
    setup () {
        provide("echarts", echarts)
        provide("axios", axios)

    //     let res = reactive({})
    //     const  startTimer = () => {
    //     setInterval(async() => {
    //         console.log('刷新web'+ Math.round(Math.random()*1000));
    //         res = await axios.post('https://api-open.cheqizhilian.com/api/v1/dashboard')
    //         console.log(res);
    //         localStorage.setItem('state', JSON.stringify(res.data))
    //   }, 60*1000);
    //  };
        
        async function getState () {
            let res = reactive({})
            res = await axios.post('https://api-open.cheqizhilian.com/api/v1/dashboard')
            console.log(res);
            localStorage.setItem('state', JSON.stringify(res.data))
        }
        onMounted(() => {
            getState()
            // startTimer()
            setInterval(()=>{
                window.location.reload()
            },10*60*1000)
        })
        onUnmounted(() => {
            // clearInterval(startTimer);
        });
    }
}
</script>

<style lang="less">
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
li {
    list-style: none;
}
p {
    margin: 0;
    padding: 0;
}
body {
    height: 100vh;
    background: url("./assets/img/pageBg.png") center no-repeat;
    background-size: cover;
}
</style>
